export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'المشاريع',
        id: 'developments',
        elements: [
            {
                text: 'نخيل',
                id: 'nakheel'
            },
            {
                text: 'ميدان',
                id: 'meydan'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'نوعية المشاريع',
        id: 'project_type',
        elements: [
            {
                text: 'فلل',
                id: 'villas'
            },
            {
                text: 'شقق',
                id: 'apartments'
            },
            {
                text: 'أراضي',
                id: 'plots'
            },
            {
                text: 'شقق وفلل',
                id: 'apartments_villas'
            },
            {
                text: 'أراضي وفلل وشقق',
                id: 'plots_villas_apartments'
            },
            {
                text: 'عرض الكل',
                id: '_all_project_type'
            },
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'البنية التحتي',
        id: 'infrastructure',
        elements: [
            {
                text: 'معالم',
                id: 'landmarks'
            },
            {
                text: 'المواصلات (مترو)',
                id: 'transport'
            },
            {
                text: 'التعليم',
                id: 'education'
            },
            {
                text: 'الصحة',
                id: 'health'
            },
            {
                text: 'التجزئة والتسوق',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}